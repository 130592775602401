import { defineRule } from 'vee-validate'

export const requiredRule = defineRule('requiredRule', (value: string) => {
  return !!value || 'Questo campo è obbligatorio'
})

export const emailRule = defineRule('emailRule', (value: string) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value) || "L'email inserita non è valida"
})

export const italianPhoneRule = defineRule('italianPhoneRule', (value: string) => {
  // Regular expression for Italian phone number
  const phoneRegex = /^(\+\d{1,3})?(\d{6,14})$/;
  return phoneRegex.test(value) || 'Il numero di telefono inserito non è non valido'
})