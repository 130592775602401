import { defineStore } from 'pinia'

const STORAGE_KEY_EMAIL = 'USER:EMAIL'
const STORAGE_KEY_PHONE = 'USER:PHONE'

export const useUserStore = defineStore('user', () => {
  const { loadRef, saveValue } = usePersistedSettings()

  const email = ref('')
  const phone = ref('')

  const setEmail = (value: string) => {
    email.value = value
    saveValue(STORAGE_KEY_EMAIL, value)
  }

  const setPhone = (value: string) => {
    phone.value = value
    saveValue(STORAGE_KEY_PHONE, value)
  }

  onMounted(() => {
    loadRef(email, STORAGE_KEY_EMAIL)
    loadRef(phone, STORAGE_KEY_PHONE)
  })

  return {
    email,
    phone,

    setEmail,
    setPhone,
  }
})
