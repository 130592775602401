<script setup lang="ts">
import Panel from 'primevue/panel'
import ChevronDownIcon from '@heroicons/vue/20/solid/ChevronDownIcon'

const props = defineProps<{
  privacyAll?: boolean,
  privacy1?: boolean,
  privacy2?: boolean,
  privacy3?: boolean,
  privacy4?: boolean,
  hasError?: boolean,
  error?: string,
}>()

const emit = defineEmits(['change:all', 'change:privacy1', 'change:privacy2', 'change:privacy3', 'change:privacy4'])

const all = ref(false)
const collapsed = ref(true)

const togglePrivacy1 = (value: boolean) => emit('change:privacy1', value)
const togglePrivacy2 = (value: boolean) => emit('change:privacy2', value)
const togglePrivacy3 = (value: boolean) => emit('change:privacy3', value)
const togglePrivacy4 = (value: boolean) => emit('change:privacy4', value)

const togglePrivacyAll = () => {
  all.value = !all.value
  togglePrivacy1(all.value)
  togglePrivacy2(all.value)
  togglePrivacy3(all.value)
  togglePrivacy4(all.value)
}

watch(() => [
  props.privacy1,
  props.privacy2,
  props.privacy3,
  props.privacy4,
], (value: boolean[]) => {
  const allChecked = value.every(p => p)

  // If all privacy values are singularly checked but
  // the all-check is unchecked, check it.
  // Vice-versa, if the all-check is checked but at least
  // one privacy check is unchecked then uncheck the all-check
  // as that condition won't be true anymore
  if (allChecked && !all.value) {
    all.value = true
  } else if (!allChecked && all.value) {
    all.value = false
  }
})

watch(() => props.hasError, (value: boolean) => {
  // Open the collapsible pane to show the error
  if (value) {
    collapsed.value = false
  }
}, { immediate: true })
</script>

<template>
  <div style="padding: 2px">
    <Panel class="relative w-full p-6 ring-2 ring-gray-200 rounded-[30px]" :collapsed="collapsed" toggleable>
      <template #header>
        <div class="flex flex-col justify-center items-start gap-2">
          <div class="flex justify-center items-start gap-2">
            <Checkbox id="privacyAll" class="min-w-6" :model-value="all" name="Privacy 1" binary
              @change="togglePrivacyAll" />
            <label for="privacyAll" class="text-xs text-slate-600 text-start cursor-pointer" @click="togglePrivacyAll">
              Accetto l'<NuxtLink class="text-primary/80 hover:text-primary/50 font-bold" to="/privacy-e-cookie-policy"
                title="Informative privacy" target="_blank">informativa privacy
              </NuxtLink> e <NuxtLink class="text-primary/80 hover:text-primary/50 font-bold" to="/termini-e-condizioni"
                title="Termini di servizio" target="_blank">
                termini e condizioni
              </NuxtLink> del sito RisparmiaTu<span class="text-xs">*</span> e tutti i consensi ai trattamenti.
              Sono a conoscenza del fatto che non tutti i consensi siano obbligatori.
            </label>
          </div>
          <div
            class="flex justify-start items-center ml-8 text-xs text-left text-secondary hover:text-secondary/80 font-bold cursor-pointer"
            @click="collapsed = !collapsed">
            Gestisci singolarmente
            <ChevronDownIcon class="h-6 w-6" />
          </div>
        </div>
      </template>
      <template #togglericon>&nbsp;</template>
      <div class="w-full flex flex-col items-start justify-center gap-4 mt-2">
        <div class="flex justify-start items-start gap-2">
          <Checkbox id="privacy1" class="min-w-6" :model-value="privacy1" name="Privacy 1" binary
            @input="togglePrivacy1" />
          <div class="flex flex-col justify-center items-start gap-2">
            <label for="privacy1" class="text-xs text-slate-600 text-start cursor-pointer"
              @click="togglePrivacy1(!privacy1)">
              Richiedendo il contatto confermo di aver letto l'<NuxtLink
                class="text-primary/80 hover:text-primary/50 font-bold" to="/privacy-e-cookie-policy"
                title="Informative privacy" target="_blank">informativa privacy
              </NuxtLink> e <NuxtLink class="text-primary/80 hover:text-primary/50 font-bold" to="/termini-e-condizioni"
                title="Termini di servizio" target="_blank">
                termini e condizioni
              </NuxtLink> del sito RisparmiaTu<span class="text-xs">*</span>
            </label>
            <span v-if="hasError" class="text-xs text-red-400 font-bold text-start">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="flex justify-start items-center gap-2">
          <Checkbox id="privacy2" class="min-w-6" :model-value="privacy2" name="Privacy 2" binary @input="togglePrivacy2"
            required />
          <label for="privacy2" class="text-xs text-slate-600 text-start cursor-pointer"
            @click="togglePrivacy2(!privacy2)">
            Acconsento al trattamento dei miei dati personali per finalità di marketing
          </label>
        </div>
        <div class="flex justify-start items-center gap-2">
          <Checkbox id="privacy3" class="min-w-6" :model-value="privacy3" name="Privacy 3" binary
            @input="togglePrivacy3" />
          <label for="privacy3" class="text-xs text-slate-600 text-start cursor-pointer"
            @click="togglePrivacy3(!privacy3)">
            Acconsento al trattamento dei miei dati personali per finalità di cessione a terzi
          </label>
        </div>
        <div class="flex justify-start items-center gap-2">
          <Checkbox id="privacy4" class="min-w-6" :model-value="privacy4" name="Privacy 4" binary
            @input="togglePrivacy4" />
          <label for="privacy4" class="text-xs text-slate-600 text-start cursor-pointer"
            @click="togglePrivacy4(!privacy4)">
            Acconsento al trattamento dei miei dati personali per finalità di profilazione
          </label>
        </div>
      </div>
    </Panel>
  </div>
</template>