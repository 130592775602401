import type { EmailPayload } from "~/types"

export const useEmail = () => {
  const { setLoading } = useUIStore()
  const { businessEmail, engApiToken } = useEnvVariables()
  const toast = useSharedToast()

  const sendNewsletterSubscriptionEmail = async (
    email: string,
    privacies: {
      privacy1: boolean,
      privacy2: boolean,
      privacy3: boolean,
      privacy4: boolean,
    }
  ): Promise<void> => {
    try {
      setLoading(true)

      const emailPayload: EmailPayload = {
        subject: '[SOTTOSCRIZIONE NEWSLETTER]',
        body: `
          <p>Ricevuta richiesta di iscrizione da: <strong>${email}</strong>.</p>
          <br />
          <p>Le scelte privacy dell'utente sono:</p>
          <ul>
          <li>conferma lettura informativa privacy: <strong>${privacies.privacy1}</strong>;</li>
          <li>trattamento dati personali finalita' di marketing: <strong>${privacies.privacy2}</strong>;</li>
          <li>trattamento dati personali finalita' di cessione a terzi: <strong>${privacies.privacy3}</strong>;</li>
          <li>trattamento dati personali finalita' di profilazione: <strong>${privacies.privacy4}</strong>.</li>
        </ul>
        `,
        to: [businessEmail],
      }

      const response = await fetch('/api/email/send', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${engApiToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailPayload)
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      toast.add({
        severity: 'success',
        summary: 'Iscrizione alla newsletter',
        detail: 'La tua iscrizione alla neswletter è avvenuta con successo',
        group: 'bc',
        life: 10_000,
      })
    } catch (error) {
      toast.add({
        severity: 'warn',
        summary: 'Qualcosa è andato storto',
        detail: 'Per favore riprovare a breve dopo aver ricaricato la pagina.',
        group: 'bc',
        life: 10_000,
      })
      throw new Error('An error occurred while subscribing to the newsletter');
    } finally {
      setLoading(false)
    }
  }

  const sendContactUsEmail = async (
    email: string,
    message: string,
    privacies: {
      privacy1: boolean,
      privacy2: boolean,
      privacy3: boolean,
      privacy4: boolean,
    }
  ): Promise<void> => {
    try {
      setLoading(true)

      const emailPayload: EmailPayload = {
        subject: '[CONTATTACI]',
        body: `
          <p>Ricevuta email da: <strong>${email}</strong>.</p>
          <br />
          <p>messaggio: ${message}</p>
          <br />
          <p>Le scelte privacy dell'utente sono:</p>
          <ul>
            <li>conferma lettura informativa privacy: <strong>${privacies.privacy1}</strong>;</li>
            <li>trattamento dati personali finalita' di marketing: <strong>${privacies.privacy2}</strong>;</li>
            <li>trattamento dati personali finalita' di cessione a terzi: <strong>${privacies.privacy3}</strong>;</li>
            <li>trattamento dati personali finalita' di profilazione: <strong>${privacies.privacy4}</strong>.</li>
          </ul>
        `,
        to: [businessEmail],
      }

      const response = await fetch('/api/email/send', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${engApiToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailPayload)
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      toast.add({
        severity: 'success',
        summary: 'Invio messaggio',
        detail: 'Il tuo messaggio è stato inviato con successo, un collaboratore ti rispondera al più presto.',
        group: 'bc',
        life: 10_000,
      })
    } catch (error) {
      toast.add({
        severity: 'warn',
        summary: 'Qualcosa è andato storto',
        detail: 'Per favore riprovare a breve dopo aver ricaricato la pagina.',
        group: 'bc',
        life: 10_000,
      })
      console.error(error)
      throw new Error('An error occurred while subscribing to the newsletter');
    } finally {
      setLoading(false)
    }
  }

  return {
    sendNewsletterSubscriptionEmail,
    sendContactUsEmail
  }
}